import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Section2 from './components/Section2'; // Update the import to Section2
import FeatureCarousel from './components/FeatureCarousel';
import WhySection from './components/WhySection';
import FeatureDescription from './components/FeatureDescription'; 
import BenefitSection from './components/BenefitSection';
import CommunitySection from './components/CommunitySection'; 
import ContactSection from './components/ContactSection'; 
import Footer from './components/Footer'; 
import FormModal from './components/FormModal';
import { SpeedInsights } from "@vercel/speed-insights/react"
//import Blog from './components/Blog'; // Import the new Blog component


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={
          <>
            <Hero />
            <Section2 />
            <FeatureCarousel />
            <WhySection />
            <FeatureDescription />
            <BenefitSection />
            <CommunitySection />
            <ContactSection />
            <Footer />
          </>
        } />
        
      </Routes>
    </Router>
  );
}

export default App;

