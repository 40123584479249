import React from 'react';
import './FeatureDescription.css'; // Import the CSS file
import Feature1_2 from '../assets/mesdo-post-create.svg';
import Feature1_3 from '../assets/job-track.svg';
import Feature1_4 from '../assets/mesdo-filter-job.svg';
import Feature1_5 from '../assets/mesdo-research-blogs.svg';
import Feature1_6 from '../assets/mesdo-healthcare-tailored.svg';
import LogoIcon from '../assets/Icon.svg';

const FeatureDescription = () => {
    return (
      <div className="feature-description-container">
        <div className="feature-description-heading">
          <h2>
            All the Tools You Need, <br />
            Designed For <span className="healthcare-text-gradient">Healthcare</span>.
          </h2>
        </div>
  
        <div className="feature-description-grid">
          {/* Section 1 */}
          <div className="feature-item section section-1">
          <div className="text-content">
            <img src={LogoIcon} alt="Icon" className="feature-icon" />
            <h3>Connect with healthcare professionals across India</h3>
            <p>
              Build your professional network within the healthcare industry. Mesdo makes it easy 
              to connect with peers, mentors, and organizations that can help you advance your career.
            </p>
          </div>
          <img src={Feature1_2} alt="Feature 1" className="feature-image" />
         </div>

  
          {/* Section 2 */}
            <div className="feature-item section-2">
            <div className="text-content">
                <h3>Job Application Tracker</h3>
                <p>Monitor your job applications and interview schedules.</p>
            </div>
            <img src={Feature1_3} alt="Job Application Tracker" className="feature-image" />
            </div>

  
          {/* Section 3 */}
          <div className="feature-item section section-3"> {/* Add both .section and .section-3 */}
            <img src={Feature1_4} alt="Filter Icon" className="feature-icon" />
            <h3>Filter</h3>
            <p>
              Use smart filters to narrow down job opportunities by location, experience, specialization, 
              and more—helping you quickly find the perfect fit for your career goals.
            </p>
          </div>
  
          {/* Section 4 */}
          <div className="feature-item section section-4"> {/* Add both .section and .section-4 */}
            <h3>Stay Updated with Research & News</h3>
            <p>
              Access the most recent research papers, industry news, and healthcare developments all in one place. 
              Mesdo keeps you informed about the latest trends and innovations in the healthcare sector.
            </p>
            <img src={Feature1_5} alt="Research & News" className="feature-image" />
          </div>
  
          <div className="feature-item section section-5"> {/* Add both .section and .section-5 */}
            <div className="text-content">
                <h3>Find the perfect healthcare job</h3>
                <p>
                    Explore job opportunities tailored to your skills and experience in the healthcare sector. 
                    Our platform offers a wide range of positions, from clinical roles to administrative positions.
                </p>
            </div>
            <img src={Feature1_6} alt="Healthcare Job" className="feature-image" />
        </div>

        </div>
      </div>
    );
};

export default FeatureDescription;
